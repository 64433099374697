import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import {
  RedirectToSignIn,
  SignedIn,
  SignedOut,
  useAuth,
  useUser,
} from "@clerk/clerk-react";
import { useFlags } from "launchdarkly-react-client-sdk";
import "mac-scrollbar/dist/mac-scrollbar.css";
import { PrimeReactProvider } from "primereact/api";
import React, { useEffect } from "react";
import { MapProvider } from "react-map-gl";
import { Route, Routes, useNavigate } from "react-router";
import { AppLayout } from "./layouts/app-layout";
import { AuthenticationLayout } from "./layouts/authentication-layout";
import { SignInPage, SignUpPage } from "./pages/authentication";
import { PasswordResetPage } from "./pages/authentication/password-reset-page";
import { CARBReportPage } from "./pages/carb/list";
import { CommunityPage } from "./pages/community";
import { ConnectionsPage } from "./pages/connections";
import { DocLibraryPage } from "./pages/doc-library";
import { EvidenceEditPage } from "./pages/doc-library/evidence/edit";
import { EvidenceTab } from "./pages/doc-library/evidence/list";
import { InspectionTemplatesEditPage } from "./pages/doc-library/inspections-templates/inspection-templates-edit";
import { InspectionTemplatesListTab } from "./pages/doc-library/inspections-templates/inspection-templates-list";
import { ProtocolsEditPage } from "./pages/doc-library/protocols/edit";
import { ProtocolsLibraryTab } from "./pages/doc-library/protocols/protocols-library-tab";
import DocumentsPage from "./pages/documents";
import { EmergencyGuidesViewPage } from "./pages/emergency-guides";
import EVSEManagementPage from "./pages/evse";
import { FoundationPage } from "./pages/foundation";
import { GloveManagementListPage } from "./pages/glove-management";
import { GloveManagementAssignmentPage } from "./pages/glove-management/assign-gloves";
import { GloveManagementSendForTestingPage } from "./pages/glove-management/build-inspection-order";
import { GloveManagementInspectionOrdersListPage } from "./pages/glove-management/glove-inspection-orders-list";
import { GloveManagementOrdersListPage } from "./pages/glove-management/glove-orders-list";
import { GloveManagementOrderPage } from "./pages/glove-management/order-new-gloves";
import { HomePage } from "./pages/home";
import { InspectionsListPage } from "./pages/inspections/list";
import { InspectionsViewPage } from "./pages/inspections/view";
import { MyActionEditPage } from "./pages/my/actions-edit";
import { MyActionsListPage } from "./pages/my/actions-list";
import { MyCertificatesListPage } from "./pages/my/certificates-list";
import { MyCourseViewerPage } from "./pages/my/course-viewer";
import { MyProtocolsPage } from "./pages/my/protocols";
import { MyRefresherViewerPage } from "./pages/my/refresher-viewer";
import { MyTrainingPage } from "./pages/my/training";
import { OrgManagementPage } from "./pages/org-management";
import { MembersListPage } from "./pages/org-management/members/list";
import { SiteEditTab } from "./pages/org-management/sites/edit";
import { SitePage } from "./pages/org-management/sites/list";
import { TeamEditMembersTab } from "./pages/org-management/teams/edit";
import { TeamsListPage } from "./pages/org-management/teams/list";
import { ProjectsEditPage } from "./pages/playbooks/edit";
import { ProjectsListPage } from "./pages/playbooks/list";
import { ProfilePage } from "./pages/profile";
import { SettingsPage } from "./pages/settings";
import { TrainingListPage } from "./pages/training";
import { VehiclesEditPage } from "./pages/vehicles/edit";
import { VehiclesListPage } from "./pages/vehicles/list";
import { VehicleCsvImporter } from "./pages/vehicles/vehicle-csv-import";
import { VendorIntegrationsPage } from "./pages/vendor-integrations";
import { VendorsListPage } from "./pages/vendors";
import { VendorsEditPage } from "./pages/vendors/edit";
import { VMRSIndexPage } from "./pages/vmrs";
import { VMRSReportPage } from "./pages/vmrs/report";
import { AppInsightsProvider } from "./providers/app-insights-provider";
import { LDIdentifyProvider } from "./providers/ld-identify-provider";
import { axleTheme } from "./theme";
import { Settings } from "./types";
import { SaasProvider } from "@saas-ui/react";

const AppRoutes: React.FC = () => {
  const { enableConnections, isDemoMode, enableDocuments, enableVmrs } =
    useFlags();

  const { orgRole } = useAuth();
  const isAdmin = orgRole === "admin";

  return (
    <Routes>
      <Route path="/sign-in" element={<AuthenticationLayout />}>
        <Route index element={<SignInPage />} />
      </Route>
      <Route path="/sign-up" element={<AuthenticationLayout />}>
        <Route index element={<SignUpPage />} />
      </Route>
      <Route path="/sign-out" element={<AuthenticationLayout />}>
        <Route index element={<SignUpPage />} />
      </Route>
      <Route path="/password-reset" element={<AuthenticationLayout />}>
        <Route index element={<PasswordResetPage />} />
      </Route>
      <Route
        path="/"
        element={
          <>
            <SignedIn>
              <AppLayout />
            </SignedIn>
            <SignedOut>
              <RedirectToSignIn />
            </SignedOut>
          </>
        }
      >
        <Route index element={<MyTrainingPage />} />
        <Route path="/my/courses" element={<MyTrainingPage />} />
        <Route path="/my/certificates" element={<MyCertificatesListPage />} />
        <Route path="my/courses/:id" element={<MyCourseViewerPage />} />
        <Route path="my/refreshers/:id" element={<MyRefresherViewerPage />} />
        <Route path="/my/inspections" element={<InspectionsListPage />} />
        <Route path="/my/inspections/:id" element={<InspectionsViewPage />} />
        <Route path="my/protocols" element={<MyProtocolsPage />} />
        <Route path="my/tasks" element={<MyActionsListPage />} />
        <Route path="my/tasks/:id" element={<MyActionEditPage />} />

        {isDemoMode && isAdmin && (
          <>
            <Route path="doc-library" element={<DocLibraryPage />}>
              <Route path="evidence" element={<EvidenceTab />} />
              <Route path="evidence/:id" element={<EvidenceEditPage />} />
              <Route path="protocols" element={<ProtocolsLibraryTab />} />
              <Route path="protocols/:id" element={<ProtocolsEditPage />} />
              <Route
                path="inspection-templates"
                element={<InspectionTemplatesListTab />}
              />
              <Route
                path="inspection-templates/:id"
                element={<InspectionTemplatesEditPage />}
              />
            </Route>
            <Route path="projects" element={<ProjectsListPage />} />
            <Route path="projects/:id" element={<ProjectsEditPage />} />
            <Route
              path="tools/glove-management"
              element={<GloveManagementListPage />}
            />
            <Route
              path="tools/glove-management/assign"
              element={<GloveManagementAssignmentPage />}
            />
            <Route
              path="tools/glove-management/orders"
              element={<GloveManagementOrdersListPage />}
            />
            <Route
              path="tools/glove-management/inspections"
              element={<GloveManagementInspectionOrdersListPage />}
            />
            <Route
              path="tools/glove-management/inspections/new"
              element={<GloveManagementSendForTestingPage />}
            />
            <Route
              path="tools/glove-management/orders/new"
              element={<GloveManagementOrderPage />}
            />
            <Route path="vendors" element={<VendorsListPage />} />
            <Route path="vendors/new" element={<VendorsEditPage />} />
            <Route path="vendors/:id" element={<VendorsEditPage />} />
            <Route path="vehicles" element={<VehiclesListPage />} />
            <Route
              path="vehicles/import/csv"
              element={<VehicleCsvImporter />}
            />
            <Route path="vehicles/:id" element={<VehiclesEditPage />} />
            <Route path="evse-management" element={<EVSEManagementPage />} />
            <Route path="vehicles/create" element={<VehiclesEditPage />} />
            <Route path="carb-tracker" element={<CARBReportPage />} />
            <Route path="foundation" element={<FoundationPage />} />
            <Route
              path="vendor-integrations"
              element={<VendorIntegrationsPage />}
            />
            <Route path="community" element={<CommunityPage />} />
          </>
        )}
        {enableConnections && isAdmin && (
          <>
            <Route path="connections" element={<ConnectionsPage />} />
            <Route path="connections/:tab" element={<ConnectionsPage />} />
          </>
        )}
        {isAdmin && (
          <>
            <Route path="/training" element={<TrainingListPage />} />
            <Route path="org-management" element={<OrgManagementPage />}>
              <Route path="sites" element={<SitePage />} />
              <Route path="sites/:id" element={<SiteEditTab />} />
              <Route path="teams" element={<TeamsListPage />} />
              <Route path="teams/:id" element={<TeamEditMembersTab />} />
              <Route path="members" element={<MembersListPage />} />
            </Route>
          </>
        )}
        {isDemoMode && (
          <>
            <Route
              path="tools/emergency-guides"
              element={<EmergencyGuidesViewPage />}
            />
          </>
        )}
        {enableVmrs && (
          <>
            <Route path="/vmrs" element={<VMRSIndexPage />} />
            <Route path="/vmrs/:id" element={<VMRSReportPage />} />
          </>
        )}

        {enableDocuments && (
          <Route path="documents" element={<DocumentsPage />} />
        )}

        <Route path="settings" element={<SettingsPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="debug" element={<HomePage />} />
      </Route>
    </Routes>
  );
};

const App: React.FC<{ settings: Settings }> = ({ settings }) => {
  const { whitelabelConfiguration } = useFlags();
  const { user } = useUser();

  const navigate = useNavigate();

  const theme = extendTheme(axleTheme, {
    colors: {
      brand: { ...whitelabelConfiguration?.brand },
    },
  });

  useEffect(() => {
    if (user && user.publicMetadata.password_reset_is_required) {
      navigate("/password-reset?required=true");
    }

    if (
      window.location.pathname === "/password-reset" &&
      window.location.search === "?required=true" &&
      user &&
      !user.publicMetadata.password_reset_is_required
    ) {
      navigate("/");
    }
  }, [user?.publicMetadata.password_reset_is_required]);

  return (
    <AppInsightsProvider settings={settings}>
      <ChakraProvider theme={theme}>
        <LDIdentifyProvider>
          <PrimeReactProvider>
            <MapProvider>
              <AppRoutes />
            </MapProvider>
          </PrimeReactProvider>
        </LDIdentifyProvider>
      </ChakraProvider>
    </AppInsightsProvider>
  );
};

export default App;
