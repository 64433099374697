import { Box, Button, Card, CardBody, Center, Heading, HStack, Icon, IconButton, Spinner, Text, VStack } from "@chakra-ui/react";
import { CSVImporter } from "csv-import-react";
import { ArrowLeftIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { BiError } from "react-icons/bi";
import { HiCheckCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useCreateVehicles } from "../../api/endpoints/vehicles";
import { PageHeader } from "../../components/page-header";

export const VehicleCsvImporter: React.FC = () => {
    const { mutate: importVehicles, isError, isSuccess } = useCreateVehicles();
    const navigate = useNavigate();

    const createVehicleImportReq = async (data: { rows: { values: { vin: any } }[] }) => {
        const vins = data.rows.map(({ values }) => String(values?.vin));
        await importVehicles({ vins });
    };

    const csvImporter = useMemo(() => (
        <Box>
            <CSVImporter
                isModal={false}
                onComplete={(data) => {
                    setStep('submit');
                    createVehicleImportReq(data);
                }}
                template={{
                    columns:
                        [
                            {
                                "name": "VIN",
                                "key": "vin",
                                "required": true,
                                "description": "The unique Vehicle Identification Number assigned to this vehicle.",
                                "suggested_mappings": ["VIN", "Vehicle Identification Number"]
                            },
                        ],
                }}
                primaryColor="#204B36"
            />
        </Box>
    ), []);

    const loader = (
        <Center height={"75%"}>
            <Spinner
                thickness='4px'
                speed='1.5s'
                emptyColor='gray.200'
                color='brand.primary.500'
                height={24}
                width={24}
            />
        </Center>
    );

    const success = (
        <Center height={"75%"}>
            <VStack px={4}>
                <HStack>
                    <Heading as='h1' size="4xl" textAlign={"left"}>Success!</Heading>
                    <Icon as={HiCheckCircle} color="brand.primary.500" boxSize={6} />
                </HStack>
                <VStack gap={4} width={"55%"}>
                    <Text color={"brand.accent"} textAlign={"center"}>
                        Your vehicles are now importing. Please check back in a few minutes to see updated information on the vehicles page.
                    </Text>
                </VStack>
            </VStack >
        </Center>
    );

    const error = (
        <Center height={"75%"}>
            <VStack px={4} gap={4}>
                <HStack>
                    <Heading as='h1' size="4xl">Something went wrong</Heading>
                    <Icon as={BiError} color="red.500" boxSize={6} />
                </HStack>
                <Button onClick={() => setStep('import')} variant={"solid"} colorScheme="brand.primary">
                    Try again
                </Button>
            </VStack >
        </Center>
    );

    const stepper = {
        'import': csvImporter,
        'submit': loader,
        'success': success,
        'error': error,
    }

    const [step, setStep] = useState<keyof typeof stepper>('import');

    useEffect(() => {
        if (isSuccess) {
            setStep('success');
        }
    }, [isSuccess])

    useEffect(() => {
        if (isError) {
            setStep('error');
        }
    }, [isError])

    return (
        <Box height={"110vh"} overflow={"scroll"}>
            <PageHeader title="VMRS AI Assistant"></PageHeader>
            <Card variant={"outline"} m={5} height={"100%"}>
                <CardBody>
                    <Box height={"100%"}>
                        <HStack>
                            <IconButton
                                icon={<ArrowLeftIcon />}
                                onClick={() => {
                                    navigate("/vehicles");
                                }}
                                aria-label={"Return to Vehicles"}
                            />
                            <Heading size={"sm"}>Return to vehicles list</Heading>
                        </HStack>
                        {stepper[step]}
                    </Box>
                </CardBody>
            </Card>
        </Box>
    );
}