import { useAuth } from "@clerk/clerk-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { ApiServiceErr } from ".";
import { CARBReportLine, Vehicle, VehicleBattery, VehicleBatteryDisposalOrder } from "../../types";

export const useVehicles = () => {
  const { getToken } = useAuth();
  return useQuery<Vehicle[], ApiServiceErr>({
    queryKey: ["vehicles"],
    queryFn:  async () => {
      const result =  await axios.get(
        `/api/v2/vehicles`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    }
  });
}

export const useCARBReport = () => {
  const { getToken } = useAuth();
  return useQuery<CARBReportLine[], ApiServiceErr>({
    queryKey: ["carb-report"],
    queryFn:  async () => {
      const result =  await axios.get(
        `/api/v2/vehicles/carb-report`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    }
  });
}

export const useVehicleMakes = () => {
  const { getToken } = useAuth();
  return useQuery<string[], ApiServiceErr>({
    queryKey: ["makes"],
    queryFn:  async () => {
      const result =  await axios.get(
        `/api/v2/vehicles/makes`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    }
  });
}

export const useVehicleModels = (make: string) => {
  const { getToken } = useAuth();
  return useQuery<string[], ApiServiceErr>({
    queryKey: ["models", make],
    queryFn:  async () => {
      const result =  await axios.get(
        `/api/v2/vehicles/makes/${make}/models`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return result.data;
    },
    enabled: make !== undefined && make.length > 0
  });
}

export const useVehicle = (id: string, enabled: boolean) => {
  const { getToken } = useAuth();
  return useQuery<Vehicle, ApiServiceErr>({
    queryKey: ["vehicle", id],
    queryFn: async () => {
      if (id.length === 0) return;
      const response = await axios.get(
        `/api/v2/vehicles/${id}`,
        { headers: { 'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}` } }
      );
      return response.data;
    },
    enabled: enabled
  });
}

export const useVehicleBattery = (vehicleId: string, enabled: boolean) => {
  const { getToken } = useAuth();
  return useQuery<VehicleBattery, ApiServiceErr>({
    queryKey: ["vehicle-batteries", vehicleId],
    queryFn:  async () => {
      if(vehicleId.length === 0) return;
      const response = await axios.get(
        `/api/v2/vehicles/${vehicleId}/battery`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    },
    enabled: enabled
  });
}

export const useVehicleBatteryDisposalOrder = (batteryId: string, enabled: boolean) => {
  const { getToken } = useAuth();
  return useQuery<VehicleBatteryDisposalOrder, ApiServiceErr>({
    queryKey: ["vehicle-battery-disposal-orders", batteryId],
    queryFn:  async () => {
      if(batteryId.length === 0) return;
      const response = await axios.get(
        `/api/v2/battery-disposal-orders/${batteryId}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
      return response.data;
    },
    enabled: enabled
  });
}

export const useUpdateVehicleBattery = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (body: {vehicleId: string, batteryId: string, vin: string, payload: VehicleBattery}) => {
      const response = await axios.put(
        `/api/v2/vehicles/${body.vehicleId}/battery/${body.batteryId}`,
        body.payload,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );

     return response.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      toast.success(`Updated vehicle battery: ${variables.vin}`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error updating vehicle battery: ${variables.vin}`)
      console.log(error);
    },
  });
}

export const useCreateBatteryDisposalOrder = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (body: {vehicleId: string, batteryId: string, isHazardousDisposal: boolean}) => {
      const response = await axios.post(
        `/api/v2/vehicles/${body.vehicleId}/batteries/${body.batteryId}/dispose`,
        {isHazordousDisposal: body.isHazardousDisposal},
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );

     return response.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      toast.success(`Created battery disposal order.`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error creating battery disposal order. Please try again.`)
      console.log(error);
    },
  });
}

export const useApproveBatteryDisposalOrder = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (body: {vehicleId: string, batteryId: string, batteryDisposalOrderId: string}) => {
      const response = await axios.post(
        `/api/v2/vehicles/${body.vehicleId}/batteries/${body.batteryId}/${body.batteryDisposalOrderId}/approve`,
        {},
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );

     return response.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      toast.success(`Approved battery disposal order.`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error approving battery disposal order. Please try again.`)
      console.log(error);
    },
  });
}

export const useCreateVehicle = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (body: {vin: string}) => {
      const response = await axios.post(
        `/api/v2/vehicles`,
        body,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );

     return response.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      toast.success(`Created vehicle: ${variables.vin}`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error creating vehicle: ${variables.vin}`)
      console.log(error);
    },
  });
}

export const useCreateVehicles = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (body: {vins: string[]}) => {
      const response = await axios.post(
        `/api/v2/vehicles/batch`,
        body,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json"}}
      );

    return response.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      toast.success(`Successfully imported vehicles`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error importing vehicles`)
      console.log(error);
    },
  });
}

export const useUpdateVehicle = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id, body}: {id: string, body: any}) => {
      const result = await axios.put(
        `/api/v2/vehicles/${id}`,
        body,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );

      return result.data;
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      queryClient.invalidateQueries({ queryKey: ['vehicle', variables.id] });
      toast.success(`Updated vehicle: ${variables.body.vin}`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error updating vehicle: ${variables.body.vin}`)
      console.log(error);
    },
  });
}

export const useDeleteVehicle = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({id}: {id: string}) => {
      return await axios.delete(
        `/api/v2/vehicles/${id}`,
        {headers: {'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`}}
      );
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      toast.success(`Deleted vehicle.`)
    },
    onError: (error, variables, context) => {
      toast.error(`Error deleting vehicle.`)
      console.log(error);
    },
  });
}

export const useImportVehiclesFromProvider = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({providerName, updatedAfter}: { providerName: string, updatedAfter: string }) => {
      const response = await axios.post(
        `/api/v2/vehicles/${providerName}/import${updatedAfter ? "?updatedAfter="+updatedAfter : ""}`,
        '',
        { headers: { 'Authorization': `Bearer ${await getToken({ template: "AxleMobility" })}`, "Content-Type": "application/json" } }
      );

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      toast.success(`Vehicle import initiated`)
    },
    onError: (error) => {
      toast.error(`Error importing vehicles`)
      console.log(error);
    },
  });
}